import React from "react";
import "./Header";
import Navbar from "./Navbar";
import SectionHeader from "./SectionHeader";

function Header() {
  return (
    <div>
      <Navbar />
      <SectionHeader />
    </div>
  );
}

export default Header;
