import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import Homepage from "./screen/Homepage";
import Signuppage from "./screen/Signuppage";
import { FormProvider } from "./utilities/Formcontext";
import Signuppage2 from "./screen/Signuppage2";
import Signuppage3 from "./screen/Signuppage3";
import Signuppage4 from "./screen/Signuppage4";
import Booktaskpage from "./screen/Booktaskpage";
import { BookProvider } from "./utilities/Bookcontext";
import Booktaskpage1 from "./screen/Booktaskpage1";
import Booktaskpage2 from "./screen/Booktaskpage2";
import Booktaskpage3 from "./screen/Booktaskpage3";
import Booktaskpage4 from "./screen/Booktaskpage4";
import Mytaskpage from "./screen/Mytaskpage";
import Schedulepage from "./screen/Schedulepage";

function App() {
  return (
    <BookProvider>
      <FormProvider>
        <Router>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/signup_step_1' element={<Signuppage />} />
            <Route path='/signup_step_2' element={<Signuppage2 />} />
            <Route path='/signup_step_3' element={<Signuppage3 />} />
            <Route path='/signup_step_4' element={<Signuppage4 />} />
            <Route path='/book_task' element={<Booktaskpage />} />
            <Route path='/book_tasks_step_1' element={<Booktaskpage1 />} />
            <Route path='/book_tasks_step_2' element={<Booktaskpage2 />} />
            <Route path='/book_tasks_step_3' element={<Booktaskpage3 />} />
            <Route path='/book_tasks_step_4' element={<Booktaskpage4 />} />
            <Route path='/my_tasks' element={<Mytaskpage />} />
            <Route path='/scheduled_task' element={<Schedulepage />} />
          </Routes>
        </Router>
      </FormProvider>
    </BookProvider>
  );
}

export default App;
