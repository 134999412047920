import React, { useContext, useState } from "react";
import "./Signuppage.css";
import facebookicon from "../assets/facebookicon.svg";
import appleicon from "../assets/appleicon.svg";
import googleicon from "../assets/googleicon.svg";
import { FormContext } from "../utilities/Formcontext";
import { useNavigate } from "react-router-dom";

function Signuppage() {
  const { formData, setFormData } = useContext(FormContext);
  const [data, setData] = useState(formData.signup_step_1 || {});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    setFormData({ ...formData, signup_step_1: data });
    navigate("/signup_step_2");
  };
  return (
    <div className='signuppage'>
      <div className='signuppage-container'>
        <div className='signuppage-left'>
          <div className='signuppage-lcnt'>
            <div className='signupwriteup'>
              <h2 className='signuptitle'>Create a free account</h2>
              <p className='signupparagraph'>
                Sign up free and connect with trusted professionals in minutes
              </p>
            </div>

            <div className='signupemailform'>
              <input
                type='email'
                name='email'
                placeholder='sample@email.com'
                value={data.email || ""}
                onChange={handleChange}
              />
              <button className='bigbtn' onClick={handleNext}>
                Continue
              </button>
            </div>

            <div className='ordivider'>
              <div className='ordividerr'></div>
              <p>OR</p>
              <div className='ordividerr'></div>
            </div>

            <div className='signupauthbtn'>
              <button>
                <img src={facebookicon} alt='' />
                <label>Continue with Facebook</label>
              </button>
              <button>
                <img src={googleicon} alt='' />
                <label>Continue with Google</label>
              </button>
              <button>
                <img src={appleicon} alt='' />{" "}
                <label>Continue with Apple</label>
              </button>
            </div>
          </div>
        </div>
        <div className='signuppage-right'></div>
      </div>
    </div>
  );
}

export default Signuppage;
