import React, { useState } from "react";
import Bookingnavbar from "../components/Bookingnavbar";
import Bookingstepnav from "../components/Bookingstepnav";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import workimageicon from "../assets/imgicon.svg";
import cleanimg1 from "../assets/clean1.png";
import cleanimg2 from "../assets/clean2.png";
import cleanimg3 from "../assets/clean3.png";
import ratingimage from "../assets/workerimg.png";
import eclipseicon from "../assets/ellipse.svg";

import "./Booktask.css";

import checked from "../assets/checked.svg";
import unchecked from "../assets/unchecked.svg";
import { workerslist } from "../utilities/images";
import { useNavigate } from "react-router-dom";

function Booktaskpage2() {
  const [atask, setAtask] = useState("morning");
  const [selectedWorker, setSelectedWorker] = useState(null);
  let navigate = useNavigate();
  const openModal = (worker) => {
    setSelectedWorker(worker); // Set the selected worker details
  };

  const closeModal = () => {
    console.log("Modal closing...");
    setSelectedWorker(null); // Close the modal
  };

  return (
    <>
      <div>
        <Bookingnavbar />
        <Bookingstepnav
          step={2}
          title='Provide more information about this task We’ll use these informations to match you with technician close to your area.'
        />
        <div className='booktasksteptwo_section'>
          <div className='bttsleft'>
            <h2 className='bttslefttitle'>Filter professionals</h2>
            <div className='bttsleftitems'>
              <div className='bttsleftdate_filter'>
                <label htmlFor='' className='btdftitle'>
                  Date
                </label>
                <div className='bttsdf_items'>
                  <button className='bttsdf_item'>
                    <label htmlFor=''>Today</label>
                  </button>
                  <button className='bttsdf_item'>
                    <label htmlFor=''>Within 3 days</label>
                  </button>
                  <button className='bttsdf_item'>
                    <label htmlFor=''>Within a week</label>
                  </button>
                  <button className='bttsdf_item'>
                    <label htmlFor=''>This month</label>
                  </button>
                </div>
              </div>
              <div className='divider'></div>
              <div className='ylinputform'>
                <p className='bttsdftitle'>How big is your task?</p>
                <div
                  className={
                    atask === "morning" ? "tskitemactive" : "tskitemnotactive"
                  }
                  onClick={() => setAtask("morning")}
                >
                  <div className='tskitemleft'>
                    <label htmlFor=''>Morning</label>
                    <p>8:00am - 12:00pm</p>
                  </div>
                  <img src={atask === "morning" ? checked : unchecked} alt='' />
                </div>
                <div
                  className={
                    atask === "afternoon" ? "tskitemactive" : "tskitemnotactive"
                  }
                  onClick={() => setAtask("afternoon")}
                >
                  <div className='tskitemleft'>
                    <label htmlFor=''>Afternoon</label>
                    <p>12:00pm - 5:00pm</p>
                  </div>
                  <img
                    src={atask === "afternoon" ? checked : unchecked}
                    alt=''
                  />
                </div>
                <div
                  className={
                    atask === "evening" ? "tskitemactive" : "tskitemnotactive"
                  }
                  onClick={() => setAtask("evening")}
                >
                  <div className='tskitemleft'>
                    <label htmlFor=''>Evening</label>
                    <p>5:00pm - 9:30pm</p>
                  </div>
                  <img src={atask === "evening" ? checked : unchecked} alt='' />
                </div>
              </div>
              <div className='divider'></div>
            </div>
          </div>
          <div className='bttsright'>
            <div className='bttsright-top'>
              <h1 className='bttsright-toptitle'>Technicians available</h1>
              <div className='bttsright-topright'>
                <label htmlFor=''>Recommended</label>
                <IoIosArrowDown />
              </div>
            </div>{" "}
            <div className='workerslist-container'>
              {workerslist.map((worker, key) => (
                <div className='workerslist-item' key={key}>
                  <div className='wlitop'>
                    <div className='wlitopleft'>
                      <img
                        src={`${worker.image}`}
                        alt=''
                        className='wlitopleftimg'
                      />
                      <div
                        className={
                          worker.rating <= 3.5
                            ? "wlitopryellow"
                            : "wlitoprgreen"
                        }
                      >
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 15 15'
                          fill='#fff'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                            fill='#fff'
                          ></path>
                        </svg>
                        <label htmlFor=''>{worker.rating}</label>
                      </div>
                    </div>
                    <div className='wlitopright'>
                      <h2 className='wlitoprighttitle'>{worker.name}</h2>
                      <div className='wlitoprightbottom'>
                        <p className='greatvalue'>GREAT VALUE</p>
                        <p className='hourminimum'>
                          {worker.time} HOUR MINIMUM
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='wlimiddle'>
                    <div className='wlimleft'>
                      <p className='wlimtask'>
                        {worker.workdone} cleaning tasks
                      </p>
                      <p className='wlimexp'>{worker.experience} years exp</p>
                    </div>
                    <div
                      className='wlimright'
                      onClick={() => openModal(worker)}
                    >
                      <label htmlFor=''>View profile</label>
                      <IoIosArrowForward />
                    </div>
                  </div>

                  <div className='wlibottom'>
                    <h1 className='wlibottomprice'>${worker.price}/hr</h1>
                    <button
                      className='wlibottombook'
                      onClick={() => navigate("/book_tasks_step_3")}
                    >
                      Book and continue
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedWorker && (
        <div className='overlayy'>
          <div className='modal' onClick={(e) => e.stopPropagation()}>
            <div className='modaldtop'>
              <div className='modaldtop-left'>
                <img
                  className='mdtlimg'
                  src={selectedWorker.image}
                  alt={selectedWorker.name}
                />
                <div className='modaldtop-leftpr'>
                  <div className='mdtlprcnt'>
                    <h2 className='mdtltitle'>{selectedWorker.name}</h2>{" "}
                    <div className='wlitoprightbottom'>
                      <p className='greatvalue'>GREAT VALUE</p>
                      <p className='hourminimum'>
                        {selectedWorker.time} HOUR MINIMUM
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      selectedWorker.rating <= 3.5
                        ? "wlitopryellow"
                        : "wlitoprgreen"
                    }
                  >
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 15 15'
                      fill='#fff'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                        fill='#fff'
                      ></path>
                    </svg>
                    <label htmlFor=''>{selectedWorker.rating}</label>
                  </div>
                </div>
              </div>
              <div className='modaldtop-right' onClick={closeModal}>
                <IoCloseOutline />
              </div>
            </div>
            <div className='bioexcnt'>
              <div className='bioexcnt-exp'>
                <label htmlFor=''>Bio</label>
                <p className='bioexcnt-par'>{selectedWorker.bio}</p>
              </div>
              <div className='bioexcnt-exp'>
                <label htmlFor=''>My Experience</label>
                <p className='bioexcnt-par'>{selectedWorker.experiencebio}</p>
              </div>
              <div className='bioexcnt-exp'>
                <div className='workcntph'>
                  <img className='workcnticon' src={workimageicon} alt='' />
                  <label htmlFor=''>Work photos</label>
                </div>

                <div className='workcntimages'>
                  <img src={cleanimg1} alt='' className='wrkimg' />
                  <img src={cleanimg2} alt='' className='wrkimg' />
                  <img src={cleanimg3} alt='' className='wrkimg' />
                </div>
              </div>

              <div className='bioexcnt-exp'>
                <label htmlFor=''>Ratings and reviews</label>
                <div className='bioexcnt-rtw'>
                  <div className='bioexcnt-rtwitem'>
                    <div className='bioexcnt-ritwitemtop'>
                      <div className='brtwitemleft'>
                        <img src={ratingimage} alt='' className='brtilimg' />
                        <div className='brtilright'>
                          <label htmlFor='' className='brtiltitle'>
                            Benjamin K
                          </label>
                          <div className='brtilrate'>
                            <svg
                              width='13'
                              height='13'
                              viewBox='0 0 15 15'
                              fill='#000'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                                fill='#000'
                              ></path>
                            </svg>
                            <label htmlFor='' className='brtilratep'>
                              4.5
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='brtwitemright'>
                        <p className='brtwip'>Cleaning</p>
                        <img src={eclipseicon} alt='' className='brtwipcicon' />
                        <p className='brtwipdate'>Nov 27,2024</p>
                      </div>
                    </div>
                    <p className='ratingwords'>
                      Fast, professional, and got the job done perfectly!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='wlibottoms'>
              <h1 className='wlibottomprice'>${selectedWorker.price}/hr</h1>
              <button
                className='wlibottombook'
                onClick={() => navigate("/book_tasks_step_3")}
              >
                Book and continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Booktaskpage2;
