import React, { useState } from "react";
import "./Mytaskpage.css";
import Bookingnavbar from "../components/Bookingnavbar";
import timeicon from "../assets/ttime.svg";
import calendericon from "../assets/tcalendar.svg";
import locationicon from "../assets/tlocation.svg";
import profileimg from "../assets/workerimg.png";
import { useNavigate } from "react-router-dom";

function Mytaskpage() {
  const [selectedNav, setSelectedNav] = useState("scheduled");
  let navigate = useNavigate();
  return (
    <div className='mytcnt'>
      <Bookingnavbar />

      <div className='mytaskcnt'>
        <h1 className='mytaskcnttitle'>My Tasks</h1>
        <div className='mytasknav'>
          <button
            onClick={() => setSelectedNav("scheduled")}
            className={
              selectedNav === "scheduled"
                ? "mytasknav-btn mytactive"
                : "mytasknav-btn"
            }
          >
            Scheduled
          </button>
          <button
            onClick={() => setSelectedNav("completed")}
            className={
              selectedNav === "completed"
                ? "mytasknav-btn mytactive"
                : "mytasknav-btn"
            }
          >
            Completed
          </button>
        </div>

        {selectedNav === "scheduled" && (
          <div className='mytaskcntsection'>
            <div className='mytaskcntitem'>
              <div className='mytaskcntitem-top'>
                <div className='mytaskcntitem-left'>
                  <p className='mytaskitemtitle'>Pipe Repair</p>
                  <div className='btstsleftdetails'>
                    <div className='btsdetitem'>
                      <img src={calendericon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        Sun, Nov 25, 2024.
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={locationicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        No 25, Idunlami St Lagos. Nigeria
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={timeicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        9:30 Am. 1hr
                      </label>
                    </div>
                  </div>
                </div>
                <div className='mytaskcntitem-right'>
                  <img src={profileimg} alt='' className='btstsleftprofimgg' />
                  <h1 className='mytaskcntitem-rightname'>Ben Affleck</h1>
                  <div
                    className={4.5 <= 3.5 ? "wlitopryellow" : "wlitoprgreen"}
                  >
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 15 15'
                      fill='#fff'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                        fill='#fff'
                      ></path>
                    </svg>
                    <label htmlFor=''>4.5</label>
                  </div>
                </div>
              </div>
              <div className='mytaskcntitem-bottom'>
                <button
                  className='mytaskviewtbtn'
                  onClick={() => navigate("/scheduled_task")}
                >
                  View task
                </button>
              </div>
            </div>
            <div className='mytaskcntitem'>
              <div className='mytaskcntitem-top'>
                <div className='mytaskcntitem-left'>
                  <p className='mytaskitemtitle'>Pipe Repair</p>
                  <div className='btstsleftdetails'>
                    <div className='btsdetitem'>
                      <img src={calendericon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        Sun, Nov 25, 2024.
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={locationicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        No 25, Idunlami St Lagos. Nigeria
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={timeicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        9:30 Am. 1hr
                      </label>
                    </div>
                  </div>
                </div>
                <div className='mytaskcntitem-right'>
                  <img src={profileimg} alt='' className='btstsleftprofimgg' />
                  <h1 className='mytaskcntitem-rightname'>Ben Affleck</h1>
                  <div
                    className={4.5 <= 3.5 ? "wlitopryellow" : "wlitoprgreen"}
                  >
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 15 15'
                      fill='#fff'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                        fill='#fff'
                      ></path>
                    </svg>
                    <label htmlFor=''>4.5</label>
                  </div>
                </div>
              </div>
              <div className='mytaskcntitem-bottom'>
                <button
                  className='mytaskviewtbtn'
                  onClick={() => navigate("/scheduled_task")}
                >
                  View task
                </button>
              </div>
            </div>
          </div>
        )}
        {selectedNav === "completed" && (
          <div className='mytaskcntsection'>
            <div className='mytaskcntitem mytaskcntitemcompleted'>
              <div className='mytaskcntitem-top'>
                <div className='mytaskcntitem-left'>
                  <p className='mytaskitemtitle'>Pipe Repair</p>
                  <div className='btstsleftdetails'>
                    <div className='btsdetitem'>
                      <img src={calendericon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        Sun, Nov 25, 2024.
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={locationicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        No 25, Idunlami St Lagos. Nigeria
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={timeicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        9:30 Am. 1hr
                      </label>
                    </div>
                  </div>
                </div>
                <div className='mytaskcntitem-right'>
                  <img src={profileimg} alt='' className='btstsleftprofimgg' />
                  <h1 className='mytaskcntitem-rightname'>Ben Affleck</h1>
                  <div
                    className={4.5 <= 3.5 ? "wlitopryellow" : "wlitoprgreen"}
                  >
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 15 15'
                      fill='#fff'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                        fill='#fff'
                      ></path>
                    </svg>
                    <label htmlFor=''>4.5</label>
                  </div>
                </div>
              </div>
              <div className='mytaskcntitem-bottom'>
                <button className='mytaskviewtbtn'>Book again</button>
                <button className='mytaskviewbtnv'>View</button>
              </div>
            </div>
            <div className='mytaskcntitem mytaskcntitemcompleted'>
              <div className='mytaskcntitem-top'>
                <div className='mytaskcntitem-left'>
                  <p className='mytaskitemtitle'>Pipe Repair</p>
                  <div className='btstsleftdetails'>
                    <div className='btsdetitem'>
                      <img src={calendericon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        Sun, Nov 25, 2024.
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={locationicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        No 25, Idunlami St Lagos. Nigeria
                      </label>
                    </div>
                    <div className='btsdetitem'>
                      <img src={timeicon} alt='' className='btsdeticon' />
                      <label htmlFor='' className='btsdettlabel'>
                        9:30 Am. 1hr
                      </label>
                    </div>
                  </div>
                </div>
                <div className='mytaskcntitem-right'>
                  <img src={profileimg} alt='' className='btstsleftprofimgg' />
                  <h1 className='mytaskcntitem-rightname'>Ben Affleck</h1>
                  <div
                    className={4.5 <= 3.5 ? "wlitopryellow" : "wlitoprgreen"}
                  >
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 15 15'
                      fill='#fff'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z'
                        fill='#fff'
                      ></path>
                    </svg>
                    <label htmlFor=''>4.5</label>
                  </div>
                </div>
              </div>
              <div className='mytaskcntitem-bottom'>
                <button className='mytaskviewtbtn'>Book again</button>
                <button className='mytaskviewbtnv'>View</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Mytaskpage;
