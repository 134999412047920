import React, { useState } from "react";
import "./section.css";
import donesvg from "../assets/homedoneicon.svg";
import { item } from "../utilities/images";

function SectionHeader() {
 

  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchlist, setShowSearchlist] = useState(false);

  // Filter items based on the search term
  const filteredItems = item.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='sectionheader'>
      <div className='sectioncontainer'>
        <h1 className='sectionheadertitle'>
          Find Trusted Handyman Services Near You - Anytime, Anywhere
        </h1>
        <p className='sectionheaderparagraph'>
          From plumbing to carpentry, book professional help with just a few
          taps
        </p>
        <div className='search-container'>
          <div className='src-input-search'>
            <input
              type='text'
              placehdolder='What do you need help with?'
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setShowSearchlist(true);
              }}
            />
            <div className='searchbutton'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                class='w-5 h-5 text-label-tertiary'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
                ></path>
              </svg>
            </div>
          </div>

          {showSearchlist ? (
            <>
              <div
                className='invisiblebackground'
                onClick={() => setShowSearchlist(false)}
              ></div>
              <div className='src-input-searchcontainer'>
                <div className='src-input-searchlist'>
                  {filteredItems.length === 0 && <p>No items found.</p>}
                  {filteredItems.map((item, index) => (
                    <div className='searchitems'>
                      <p
                        key={index}
                        onClick={() => {
                          setSearchTerm(item);
                          setShowSearchlist(!showSearchlist);
                        }}
                      >
                        {item}
                      </p>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z'
                          fill='currentColor'
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='sectioncontainer2'>
        <h1 className='sectioncontainertitle'>
          Comprehensive handyman service for every need
        </h1>
        <p className='sectioncontainerparagraph'>
          From fixing leaks to assembling furniture, explore a wide range of
          professional services designed to make your life easier. Whatever the
          task, we’ve got the right expert for you.
        </p>
        <div className='sectiontaskcontroll'>
          <div className='sectioncontainertasklist' id='target-section'>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Plumbing</p>
            </div>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Electrical work</p>
            </div>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Carpentry</p>
            </div>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Appliance repair</p>
            </div>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Painting and decorating</p>
            </div>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Cleaning services</p>
            </div>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Laundry help</p>
            </div>
            <div className='sectiontaskitem'>
              <div className='sectiontaskimg'></div>
              <p className='sectiontasktitle'>Light installation</p>
            </div>
          </div>
        </div>
      </div>

      <div className='sectioncontainer3'>
        <div className='sectioncontainer3-left'>
          <div className='sectioncontainer3-ltop'>
            <h1 className='sltoptitle'>
              Get someone proficient for your <span>Plumbing</span> services
            </h1>
            <div className='sltoplist'>
              <div className='sltoplist-item'>
                <img src={donesvg} alt='' className='sltopsvg' />
                <p className='sltopparagraph'>Leak repairs</p>
              </div>
              <div className='sltoplist-item'>
                <img src={donesvg} alt='' className='sltopsvg' />
                <p className='sltopparagraph'>Pipe installations</p>
              </div>
              <div className='sltoplist-item'>
                <img src={donesvg} alt='' className='sltopsvg' />
                <p className='sltopparagraph'>Drain cleaning</p>
              </div>
              <div className='sltoplist-item'>
                <img src={donesvg} alt='' className='sltopsvg' />
                <p className='sltopparagraph'>Faucet and shower repairs</p>
              </div>
            </div>
          </div>

          <div className='sectioncontainer3-lbottom'>
            <button className='slbottombutton'>Get started now</button>
          </div>
        </div>
        <div className='sectioncontainer3-right'></div>
      </div>
      <div className='sectioncontainer4'>
        <h1 className='s4title'>How it works</h1>
        <div className='s4itemlist'>
          <div className='s4items'>
            <div className='s4itemstop'>
              <h2 className='s4itemsn'>1</h2>
              <h2 className='s4itemtitle'>Select a service</h2>
              <p className='s4itemparagraph'>
                Choose from a wide range of home repair and maintenance options.
              </p>
            </div>

            <button className='s4itembutton'>Select Service</button>
          </div>
          <div className='s4items'>
            <div className='s4itemstop'>
              <h2 className='s4itemsn'>2</h2>
              <h2 className='s4itemtitle'>Book a technician</h2>
              <p className='s4itemparagraph'>
                Pick a time that works for you and confirm the booking.
              </p>
            </div>

            <button className='s4itembutton'>Get started</button>
          </div>
          <div className='s4items'>
            <div className='s4itemstop'>
              <h2 className='s4itemsn'>3</h2>
              <h2 className='s4itemtitle'>Get the job done</h2>
              <p className='s4itemparagraph'>
                Relax while our expert takes care of your task.
              </p>
            </div>

            <button className='s4itembutton'>Sign in now</button>
          </div>
        </div>
      </div>

      <div className='sectioncontainer5'>
        <div className='sectioncontainer4'>
          <h1 className='s4title'>Why choose handyman</h1>
          <div className='s4itemlist'>
            <div className='s4items'>
              <div className='s4itemstop'>
                <h2 className='s4itemtitle'>Quick and easy booking</h2>
                <p className='s4itemparagraph'>
                  Schedule services with our user-friendly app.
                </p>
              </div>

              <button className='s4itembutton'>Select Service</button>
            </div>
            <div className='s4items'>
              <div className='s4itemstop'>
                <h2 className='s4itemtitle'>Trusted professionals</h2>
                <p className='s4itemparagraph'>
                  Background-checked and highly-rated technicians.
                </p>
              </div>

              <button className='s4itembutton'>Get started</button>
            </div>
            <div className='s4items'>
              <div className='s4itemstop'>
                <h2 className='s4itemtitle'>Transparent pricing</h2>
                <p className='s4itemparagraph'>
                  Know what you’ll pay before the work begins.
                </p>
              </div>

              <button className='s4itembutton'>Sign in now</button>
            </div>
          </div>{" "}
          <div className='s4itemlistt'>
            <div className='s4items'>
              <div className='s4itemstop'>
                <h2 className='s4itemtitle'>Real-Time Tracking</h2>
                <p className='s4itemparagraph'>
                  Track your technician’s arrival in real-time
                </p>
              </div>

              <button className='s4itembutton'>Select Service</button>
            </div>
            <div className='s4items'>
              <div className='s4itemstop'>
                <h2 className='s4itemtitle'>Secure payments</h2>
                <p className='s4itemparagraph'>
                  Multiple payment options, all hassle-free.
                </p>
              </div>

              <button className='s4itembutton'>Get started</button>
            </div>
          </div>
        </div>
      </div>
      <div className='sectioncontainer6'>
        <h1 className='s6title'>Are you a skilled technician?</h1>
        <p className='s6paragraph'>
          Join our network of professionals and grow your business with us.
        </p>
        <button className='slbottombutton6'>Sign Up as a Technician</button>
      </div>
      <div className='sectioncontainer4'>
        <h1 className='s4title'>Get help today</h1>
        <div className='section7items'>
          <div className='section7item'>
            <p className='section7itemparagraph'>Leak repairs</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Pipe installation</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Drain cleaning</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Faucet and shower repairs</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Wiring and installation</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Lighting fixture repairs</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Circuit breaker issues</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Appliance setup</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Furniture repairs</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Cabinet installation</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>
              Shelving and storage solutions
            </p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Wooden fixture repairs</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Refrigerator repairs</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Washing machine services</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Air conditioner maintenance</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Oven and stove repairs</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Interior wall painting</p>
          </div>
          <div className='section7item'>
            <p className='section7itemparagraph'>Exterior painting</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHeader;
