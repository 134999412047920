import React, { useState } from "react";
import Bookingnavbar from "../components/Bookingnavbar";
import Bookingstepnav from "../components/Bookingstepnav";
import checked from "../assets/checked.svg";
import unchecked from "../assets/unchecked.svg";
import "./Booktask.css";
import { useNavigate } from "react-router-dom";

function Booktaskpage1() {
  const [bar, setBar] = useState("yourlocation");
  const [tp, setTp] = useState(false);
  const [dt, setDt] = useState(false);
  const [atask, setAtask] = useState("small");
  const navigate = useNavigate();
  return (
    <div>
      <Bookingnavbar />
      <Bookingstepnav
        step={1}
        title='Provide more information about this task We’ll use these informations to match you with technician close to your area.'
      />
      <div className='booktaskstepone_section'>
        <div className='yourlocation'>
          <h2 className='yltitle' onClick={() => setBar("yourlocation")}>
            Your location
          </h2>
          {bar === "yourlocation" ? (
            <div className='ylinputform'>
              <div className='ylinputformcnt'>
                <label htmlFor=''>Your street address</label>
                <input
                  type='text'
                  name=''
                  placeholder='Enter your street address'
                />
              </div>
              <div className='ylinputformcnt'>
                <label htmlFor=''>Unit or apartment number</label>
                <input type='text' name='' placeholder='Unit/Apt' />
              </div>
              <button
                className='cntbtn'
                onClick={() => {
                  setBar("taskoptions");
                  setTp(true);
                }}
              >
                Continue
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className='yourlocation'>
          {tp ? (
            <h2 className='yltitle' onClick={() => setBar("taskoptions")}>
              Task Options
            </h2>
          ) : (
            <h2 className='yltitle'>Task Options</h2>
          )}
          {bar === "taskoptions" ? (
            <div className='ylinputform'>
              <p className='tpquestion'>How big is your task?</p>
              <div
                className={
                  atask === "small" ? "tskitemactive" : "tskitemnotactive"
                }
                onClick={() => setAtask("small")}
              >
                <div className='tskitemleft'>
                  <label htmlFor=''>Small</label>
                  <p>Estimated time - 1hr</p>
                </div>
                <img src={atask === "small" ? checked : unchecked} alt='' />
              </div>
              <div
                className={
                  atask === "medium" ? "tskitemactive" : "tskitemnotactive"
                }
                onClick={() => setAtask("medium")}
              >
                <div className='tskitemleft'>
                  <label htmlFor=''>Medium</label>
                  <p>Estimated time - 2hr</p>
                </div>
                <img src={atask === "medium" ? checked : unchecked} alt='' />
              </div>
              <div
                className={
                  atask === "large" ? "tskitemactive" : "tskitemnotactive"
                }
                onClick={() => setAtask("large")}
              >
                <div className='tskitemleft'>
                  <label htmlFor=''>Large</label>
                  <p>Estimated time - 4hr+</p>
                </div>
                <img src={atask === "large" ? checked : unchecked} alt='' />
              </div>
              <button
                className='cntbtn'
                onClick={() => {
                  setBar("detailsoftask");
                  setDt(true);
                }}
              >
                Continue
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className='yourlocation'>
          {dt ? (
            <h2 className='yltitle' onClick={() => setBar("detailsoftask")}>
              Details of the task
            </h2>
          ) : (
            <h2 className='yltitle'>Details of the task</h2>
          )}
          {bar === "detailsoftask" ? (
            <div className='ylinputform'>
              <p className='tpquestion'>
                Share the details of the task you need help with, so we can
                match you with the most qualified and available professionals.
                Don’t worry, you can always update the details later.
              </p>
              <div class='textarea-container'>
                <textarea placeholder="Hi, I need help fixing a leaking faucet in my kitchen. It’s been dripping non-stop, and I’d like to get it repaired as soon as possible. Please let me know if you're available and what the next steps are. Thanks!"></textarea>
              </div>
              <button
                className='cntbtn'
                onClick={() => navigate("/book_tasks_step_2")}
              >
                Continue
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Booktaskpage1;
