import React from "react";
import "./Homepage.css";
import Header from "../components/Header";

function Homepage() {
  return (
    <div className="homepage">
      <Header />
    </div>
  );
}

export default Homepage;
