import React from "react";
import "./Bookingstepnav.css";

function Bookingstepnav({ step, active, title }) {
  return (
    <div className='bookstepnav_container'>
      <div className='bookstepmap'>
        <div
          className={step >= 1 ? "bookstepitemactive" : "bookstepitemnotactive"}
        >
          <label htmlFor=''>1</label>
        </div>
        <div
          className={
            step >= 2 ? "bookstepwidthactive" : "bookstepwidthnotactive"
          }
        ></div>
        <div
          className={step >= 2 ? "bookstepitemactive" : "bookstepitemnotactive"}
        >
          <label htmlFor=''>2</label>
        </div>
        <div
          className={
            step >= 3 ? "bookstepwidthactive" : "bookstepwidthnotactive"
          }
        ></div>
        <div
          className={step >= 3 ? "bookstepitemactive" : "bookstepitemnotactive"}
        >
          <label htmlFor=''>3</label>
        </div>
        <div
          className={
            step >= 4 ? "bookstepwidthactive" : "bookstepwidthnotactive"
          }
        ></div>
        <div
          className={
            step === 4 ? "bookstepitemactive" : "bookstepitemnotactive"
          }
        >
          <label htmlFor=''>4</label>
        </div>
      </div>
     <p className="bookstepnav-title">{title}</p>
    </div>
  );
}

export default Bookingstepnav;
