import React, { useContext, useState } from "react";
import { FormContext } from "../utilities/Formcontext";
import { Link, useNavigate } from "react-router-dom";

function Signuppage4() {
  const { formData, setFormData } = useContext(FormContext);
  const [data, setData] = useState(formData.signup_step_4 || {});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const finalData = { ...formData, signup_step_4: data };
    console.log(finalData);
    setFormData(finalData);

    console.log("Final Form Data:", finalData);
    localStorage.removeItem("formData");
    setFormData({
      signup_step_1: {},
      signup_step_2: {},
      signup_step_3: {},
      signup_step_4: {},
    });
    navigate("/book_task");
  };
  return (
    <div className='signuppage'>
      <div className='signuppage-container'>
        <div className='signuppage-left'>
          <div className='signuppage-lcnt'>
            <div className='signupwriteup signup4typo'>
              <p className='acts4typo'>Account Setup</p>
              <h2 className='signuptitle'>
                Enter your residential address to complete sign up
              </h2>
            </div>

            <div className='signupemailform'>
              <div className='inputform'>
                <label htmlFor=''>Address</label>
                <input
                  type='text'
                  name='address'
                  placeholder='Enter your address'
                  value={data.address || ""}
                  onChange={handleChange}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>City</label>
                <input
                  type='text'
                  name='city'
                  placeholder='Enter city here'
                  value={data.city || ""}
                  onChange={handleChange}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>State/province/region *</label>
                <input
                  type='text'
                  name='state'
                  placeholder='Enter state here'
                  value={data.state || ""}
                  onChange={handleChange}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>Country</label>
                <div className='inputformcountry'>
                  <p className='countryname'>Nigeria</p>
                </div>
              </div>
            </div>

            <button className='bigbtn' onClick={handleSubmit}>
              Complete Sign up
            </button>
          </div>
        </div>
        <div className='signuppage-right'></div>
      </div>
    </div>
  );
}

export default Signuppage4;
