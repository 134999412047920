import React, { useContext, useState } from "react";
import { FormContext } from "../utilities/Formcontext";
import { useNavigate } from "react-router-dom";

function Signuppage2() {
  const { formData, setFormData } = useContext(FormContext);
  const [data, setData] = useState(formData.signup_step_2 || {});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    setFormData({ ...formData, signup_step_2: data });
    navigate("/signup_step_3");
  };
  return (
    <div className='signuppage'>
      <div className='signuppage-container'>
        <div className='signuppage-left'>
          <div className='signuppage-lcnt'>
            <div className='signupwriteup'>
              <h2 className='signuptitle'>Create a free account</h2>
              <p className='signupparagraph signupp2'>
                You’re almost there. Create your new account for
                <span>
                  {" "}
                  {formData.signup_step_1?.email || "email not fetched"}
                </span>{" "}
                by completing these details
              </p>
            </div>

            <div className='signupemailform'>
              <div className='inputform'>
                <label htmlFor=''>First Name</label>
                <input
                  type='text'
                  name='firstName'
                  placeholder='Enter your first name'
                  value={data.firstName || ""}
                  onChange={handleChange}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>Last Name</label>
                <input
                  type='text'
                  name='lastName'
                  placeholder='Enter your last name'
                  value={data.lastName || ""}
                  onChange={handleChange}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>Password</label>
                <input
                  type='password'
                  name='password'
                  placeholder='Enter your password'
                  value={data.password || ""}
                  onChange={handleChange}
                />
              </div>
              <div className='inputform'>
                <label htmlFor=''>Mobile Number</label>
                <div className='phoneinput'>
                  <label htmlFor=''>+234</label>
                  <input
                    type='phone'
                    name='phone'
                    placeholder='Enter mobile number here'
                  />
                </div>
              </div>
            </div>

            <p className='signupagreement'>
              By clicking on Continue you agree to the{" "}
              <span>Privacy policy</span> , <span>Terms </span>and{" "}
              <span>Conditions</span> of Handyman
            </p>
            <button className='bigbtn' onClick={handleNext}>
              Continue
            </button>
          </div>
        </div>
        <div className='signuppage-right'></div>
      </div>
    </div>
  );
}

export default Signuppage2;
