import React, { useState } from "react";
import Bookingnavbar from "../components/Bookingnavbar";
import { item } from "../utilities/images";
import { useNavigate } from "react-router-dom";

function Booktaskpage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchlist, setShowSearchlist] = useState(false);
  const navigate = useNavigate();

  // Filter items based on the search term
  const filteredItems = item.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Bookingnavbar />
      <div className='sectionheader sectionbook'>
        <div className='sectioncontainer sectionbookcontainer'>
          <h1 className='sectionheadertitle'>
            Find Trusted Handyman Services Near You - Anytime, Anywhere
          </h1>
          <p className='sectionheaderparagraph'>
            From plumbing to carpentry, book professional help with just a few
            taps
          </p>
          <div className='search-container'>
            <div className='src-input-search'>
              <input
                type='text'
                placehdolder='What do you need help with?'
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowSearchlist(true);
                }}
              />
              <div className='searchbutton'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  class='w-5 h-5 text-label-tertiary'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
                  ></path>
                </svg>
              </div>
            </div>

            {showSearchlist ? (
              <>
                <div
                  className='invisiblebackground'
                  onClick={() => setShowSearchlist(false)}
                ></div>
                <div className='src-input-searchcontainer'>
                  <div className='src-input-searchlist'>
                    {filteredItems.length === 0 && <p>No items found.</p>}
                    {filteredItems.map((item, index) => (
                      <div className='searchitems'>
                        <p
                          key={index}
                          onClick={() => {
                            setSearchTerm(item);
                            setShowSearchlist(!showSearchlist);
                            navigate("/book_tasks_step_1");
                          }}
                        >
                          {item}
                        </p>
                        <svg
                          width='15'
                          height='15'
                          viewBox='0 0 15 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z'
                            fill='currentColor'
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='section7items sectionbooknow'>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Leak repairs
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Pipe installation
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Drain cleaning
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Faucet and shower repairs
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Wiring and installation
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Lighting fixture repairs
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Circuit breaker issues
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Appliance setup
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Furniture repairs
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Cabinet installation
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Shelving and storage solutions
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Wooden fixture repairs
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Refrigerator repairs
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Washing machine services
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Air conditioner maintenance
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Oven and stove repairs
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Interior wall painting
            </p>
          </div>
          <div className='section7item'>
            <p
              className='section7itemparagraph'
              onClick={() => navigate("/book_tasks_step_1")}
            >
              Exterior painting
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Booktaskpage;
