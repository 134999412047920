import React, { useContext, useState } from "react";
import { FormContext } from "../utilities/Formcontext";
import { Link, useNavigate } from "react-router-dom";

function Signuppage3() {
  const { formData, setFormData } = useContext(FormContext);
  const [data, setData] = useState(formData.signup_step_3 || {});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    setFormData({ ...formData, signup_step_3: data });
    navigate("/signup_step_4");
  };
  return (
    <div className='signuppage'>
      <div className='signuppage-container'>
        <div className='signuppage-left'>
          <div className='signuppage-lcnt'>
            <div className='signupwriteup'>
              <h2 className='signuptitle'>Verify phone number</h2>
              <p className='signupparagraph signupp2'>
                To protect your account, we sent a 4-digit code to the phone
                number you provided
              </p>
              <Link to='/signup_step_2'>Edit number</Link>
            </div>

            <div className='signupemailform'>
              <div className='inputform'>
                <label htmlFor=''>Enter code</label>
                <input
                  type='number'
                  name='code'
                  placeholder='Enter code'
                  value={data.code || ""}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button className='bigbtn' onClick={handleNext}>
              Verify phone number
            </button>
            <div className='rescd'>
              <Link className='sppla' to='/signup_step_3'>
                Resend code <span>in 00:49</span> 
              </Link>
            </div>
          </div>
        </div>
        <div className='signuppage-right'></div>
      </div>
    </div>
  );
}

export default Signuppage3;
