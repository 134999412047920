import React, { useEffect, useState } from "react";
import { menuclose, menuopen } from "../utilities/images";
import { Link } from "react-router-dom";

function Bookingnavbar() {
  const [openMenu, setOpenMenu] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY <= 0) {
      // Always show navbar at the top of the page
      setIsVisible(true);
    } else if (currentScrollY > lastScrollY) {
      // Scrolling down
      setIsVisible(false);
    } else if (currentScrollY < lastScrollY) {
      // Scrolling up
      setIsVisible(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, lastScrollY]);
  return (
    <div className={`navbar ${isVisible ? "visible" : "hidden"}`}>
      <div className='navbar-container'>
        <div className='navbar-logo-left'>
          <Link to='/book_task' className='navbar-logo'>
            ProfixExpress
          </Link>
        </div>

        <div className='navbar-logo-right'>
          <div className='navbar-logo-middle'>
            <a href='#' className='navbar-logo-link'>
              Book a task
            </a>
            <a href='#' className='navbar-logo-link'>
              Become a technician
            </a>
            <a href='#' className='navbar-logo-link'>
              My tasks
            </a>
            <a href='#' className='navbar-logo-login'>
              My account
            </a>
          </div>
        </div>
        <div className='navbar-mobile'>
          <button onClick={() => setOpenMenu(!openMenu)}>
            <img src={openMenu ? menuopen : menuclose} alt='' />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Bookingnavbar;
