import React, { useState } from "react";
import Bookingnavbar from "../components/Bookingnavbar";
import Bookingstepnav from "../components/Bookingstepnav";
import profileimg from "../assets/workerimg.png";
import timeicon from "../assets/timeicon.svg";
import calendericon from "../assets/calender.svg";
import locationicon from "../assets/locationicon.svg";
import editicon from "../assets/edit.svg";
import checked from "../assets/checked.svg";
import unchecked from "../assets/unchecked.svg";
import mastercardImg from "../assets/mastercard.png";
import visaImg from "../assets/visa.png";
import verveImg from "../assets/verve.png";
import { useNavigate } from "react-router-dom";

function Booktaskpage3() {
  const [atask, setAtask] = useState("just once");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardType, setCardType] = useState("");
  let navigate = useNavigate();

  // Function to detect card type
  const detectCardType = (number) => {
    const regexPatterns = {
      mastercard: /^5[1-5][0-9]{0,14}$/,
      visa: /^4[0-9]{0,15}$/,
      verve: /^(506[0-9]|507[0-9]|650[0-9])[0-9]{0,12}$/,
    };

    for (const [type, pattern] of Object.entries(regexPatterns)) {
      if (pattern.test(number)) {
        return type;
      }
    }
    return "unknown";
  };

  // Map card type to corresponding images
  const getCardTypeImage = (type) => {
    const cardImages = {
      mastercard: mastercardImg,
      visa: visaImg,
      verve: verveImg,
    };
    return cardImages[type];
  };

  // Handle card number input
  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    setCardNumber(value);
    const detectedType = detectCardType(value);
    setCardType(detectedType);
  };

  // Handle expiration date input
  const handleExpiryDateChange = (e) => {
    const value = e.target.value.replace(/[^0-9/]/g, ""); // Allow only numbers and '/'
    if (value.length <= 5) {
      setExpiryDate(value);
    }
  };

  // Handle CVV input
  const handleCvvChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    if (value.length <= 4) {
      setCvv(value);
    }
  };
  return (
    <>
      <div>
        <Bookingnavbar />
        <Bookingstepnav
          step={3}
          title='Filter and sort to find your technician. Then view their availability to request your date and time.'
        />
        <div className='booktaskstepthree_section'>
          <div className='btstsleft'>
            <h1 className='btstsleftitle'>Task Summary</h1>
            <div className='btstsleftprof'>
              <img src={profileimg} alt='' className='btstsleftprofimg' />
              <h2 className='btstsleftprofname'>Ben Affleck</h2>
            </div>
            <div className='btstsleftdetails'>
              <div className='btsdetitem'>
                <img src={timeicon} alt='' className='btsdeticon' />
                <label htmlFor='' className='btsdetlabel'>
                  9:30 Am. 1hr
                </label>
              </div>
              <div className='btsdetitem'>
                <img src={calendericon} alt='' className='btsdeticon' />
                <label htmlFor='' className='btsdetlabel'>
                  Sun, Nov 25, 2024.
                </label>
              </div>
              <div className='btsdetitem'>
                <img src={locationicon} alt='' className='btsdeticon' />
                <label htmlFor='' className='btsdetlabel'>
                  No 25, Idunlami St Lagos. Nigeria
                </label>
              </div>
            </div>
            <button className='edittaskbtn'>
              <img src={editicon} alt='' />
              <label htmlFor=''>Edit task</label>
            </button>
            <div className='tsummarydivider'></div>
            <p className='tsmtasdetails'>Your task details</p>
            <div className='tsmtdetcnt'>
              <p className='tsmtdetcntpar'>
                Hi, I need help fixing a leaking faucet in my kitchen. It’s been
                dripping non-stop, and I’d like to get it repaired as soon as
                possible. Please let me know if you're available and what the
                next steps are. Thanks!
              </p>
            </div>
            <div className='tsummarydivider'></div>
            <p className='tsmtasdetails'>Price Details</p>
            <div className='pricedetailscnt'>
              <div className='pricedetails-item'>
                <p className='pdetails'>Hourly rate</p>
                <p className='detailsprice'>$26.93/hr</p>
              </div>
              <div className='pricedetails-item'>
                <p className='pdetails'>Trust/support fee</p>
                <p className='detailsprice'>$12.93/hr</p>
              </div>
              <div className='pricedetails-item'>
                <p className='pdetails'>Total fee</p>
                <p className='detailsprice'>$12.93/hr</p>
              </div>
            </div>
            <div className='tsummarydivider'></div>
            <button className='confirmpaymentbtn'>
              Confirm payment and chat
            </button>
          </div>
          <div className='btstsright'>
            <div className='btstsrighttop'>
              <h1 className='btstsrighttitle'>Select Frequecy</h1>
              <div className='choosesfreq'>
                <div
                  className={
                    atask === "just once" ? "tskitemactive" : "tskitemnotactive"
                  }
                  onClick={() => setAtask("just once")}
                >
                  <div className='tskitemleft'>
                    <label htmlFor=''>Just once</label>
                  </div>
                  <img
                    src={atask === "just once" ? checked : unchecked}
                    alt=''
                  />
                </div>
                <div
                  className={
                    atask === "weekly" ? "tskitemactive" : "tskitemnotactive"
                  }
                  onClick={() => setAtask("weekly")}
                >
                  <div className='tskitemleft'>
                    <label htmlFor=''>Weekly</label>
                  </div>
                  <img src={atask === "weekly" ? checked : unchecked} alt='' />
                </div>
                <div
                  className={
                    atask === "every 2 weeks"
                      ? "tskitemactive"
                      : "tskitemnotactive"
                  }
                  onClick={() => setAtask("every 2 weeks")}
                >
                  <div className='tskitemleft'>
                    <label htmlFor=''>Every 2 weeks</label>
                  </div>
                  <img
                    src={atask === "every 2 weeks" ? checked : unchecked}
                    alt=''
                  />
                </div>
                <div
                  className={
                    atask === "every 4 weeks"
                      ? "tskitemactive"
                      : "tskitemnotactive"
                  }
                  onClick={() => setAtask("every 4 weeks")}
                >
                  <div className='tskitemleft'>
                    <label htmlFor=''>Every 4 weeks</label>
                  </div>
                  <img
                    src={atask === "every 4 weeks" ? checked : unchecked}
                    alt=''
                  />
                </div>
              </div>
            </div>
            <div className='btstsrighttop'>
              <h1 className='btstsrighttitle'>Payment method</h1>
              <form>
                <div className='form-group'>
                  <label>Card Number</label>
                  <div className='cardnumbercnt'>
                    <input
                      type='number'
                      value={cardNumber}
                      onChange={handleCardNumberChange}
                      placeholder='1234 1234 1234 1234'
                      maxLength='16'
                    />
                    <div className='card-type'>
                      <img
                        src={getCardTypeImage(cardType)}
                        alt={cardType}
                        className='card-type-img'
                      />
                    </div>
                  </div>
                </div>

                <div className='expseccnt'>
                  <div className='form-group'>
                    <label>Expiration Date</label>
                    <input
                      type='text'
                      value={expiryDate}
                      onChange={handleExpiryDateChange}
                      placeholder='MM/YY'
                      maxLength='5'
                    />
                  </div>
                  <div className='form-group'>
                    <label>Security code</label>
                    <input
                      type='text'
                      value={cvv}
                      onChange={handleCvvChange}
                      placeholder='123'
                      maxLength='4'
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label>Country</label>
                  <div className='countrycnt'>
                    <label htmlFor=''>Nigeria</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='overlayy'>
        <div className='successmodal' onClick={(e) => e.stopPropagation()}>
          <h1>Success!</h1>
          <img src={profileimg} alt='' className='btstsleftprofimgg' />
          <p className='btstslefttitle'>You've booked Ben Afleck</p>
          <p className='btstsleftpara'>
            Ben is currently offline at the moment. He will reach out to you
            once he’s available soon
          </p>
          <button
            className='successmodalbtn'
            onClick={() => navigate("/my_tasks")}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default Booktaskpage3;
