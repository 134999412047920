import React, { createContext, useState, useEffect } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const initialData = JSON.parse(localStorage.getItem('formData')) || {
    signup_step_1: {},
    signup_step_2: {},
    signup_step_3: {},
    signup_step_4: {},
  };

  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};
